var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "fixed-tabs": "",
      "dark": "",
      "color": "seconday",
      "background-color": "primary"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.text,
      attrs: {
        "to": tab.to
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": _vm.$vuetify.breakpoint.smAndUp
      }
    }, [_vm._v("mdi-" + _vm._s(tab.icon))]), _vm._v(_vm._s(_vm.$vuetify.breakpoint.xs ? '' : tab.text) + " ")], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }