import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const checkAuth = async (to, from, next) => {
  if (to?.path === '/login/microsoft') {
    window.location.replace('https://api.eipl.org/auth/azure')
    return
  }

  if (store.getters.isAuth) {
    if (to.name === 'login') next({ name: 'user' })
    else next()
  } else {
    try {
      // const result = await store.dispatch('testAuth')
      await store.dispatch('testAuth')
      if (to.name === 'login') next({ name: 'user' })
      // if (store.getters.isAuth && to.name === 'login') next({ name: 'user' })
      else next()
    } catch (err) {
      if (to.name === 'login') next()
      else next({ name: 'login' })
    }
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home.vue')
  },
  {
    path: '/microsoft',
    beforeEnter() {
      window.location.replace('https://api.eipl.org/auth/azure')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter() {
      window.location.replace('https://api.eipl.org/auth/logout')
    }
  },
  {
    path: '/cms',
    name: 'cms',
    children: [
      {
        path: 'gallery',
        component: () =>
          import(/* webpackChunkName: "cms" */ '@/views/gallery.vue')
      },
      {
        path: 'navigation',
        component: () =>
          import(/* webpackChunkName: "cms" */ '@/views/navigation.vue')
      },
      {
        path: 'pages',
        component: () =>
          import(/* webpackChunkName: "cms" */ '@/views/pages.vue')
      },
      {
        path: 'statistics',
        component: () =>
          import(/* webpackChunkName: "cms" */ '@/views/statistics.vue')
      }
    ],
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/cms/statistics', query: { q: to.params.searchText } }
    },
    component: () => import(/* webpackChunkName: "cms" */ '@/views/cms.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user.vue')
  }
  // {
  //   path: '/manage',
  //   name: 'Manage',
  //   beforeEnter: checkAuth,
  //   component: () => import(/* webpackChunkName: "mgmt" */ '@/views/Management/Management.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setAppLoading', true)
  await checkAuth(to, from, next)
})

router.beforeResolve((to, from, next) => {
  //TESTING LOADER
  // setTimeout(() => {
  //   store.dispatch('setAppLoading', false)
  // }, 1500)

  store.dispatch('setAppLoading', false)
  next()
})

export default router
