var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "bottom": "",
      "close-on-content-click": false,
      "close-delay": "250",
      "offset-y": "",
      "open-on-hover": false
    },
    on: {
      "input": _vm.onInput
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-sheet', _vm._g(_vm._b({
          attrs: {
            "color": "transparent"
          }
        }, 'v-sheet', attrs, false), on), [_c('user-avatar', {
          attrs: {
            "backgroundColor": "secondary",
            "size": "35",
            "textColor": "primary",
            "user": _vm.user
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.userMenu,
      callback: function callback($$v) {
        _vm.userMenu = $$v;
      },
      expression: "userMenu"
    }
  }, [_c('v-card', {
    staticClass: "mt-2",
    attrs: {
      "flat": "",
      "tile": "",
      "width": "400"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('user-card', {
    attrs: {
      "user": _vm.user
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary",
      "text": "",
      "tile": "",
      "to": {
        name: 'logout'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-logout-variant")]), _vm._v(" LOGOUT ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }