<template>
  <v-card flat tile>
    <v-card-text class="d-flex">
      <v-sheet color="transparent" class="d-flex align-center justify-center">
        <user-avatar :user="user"></user-avatar>
      </v-sheet>
      <v-sheet
        color="transparent"
        class="d-flex flex-column align-start justify-start pl-4"
      >
        <p class="text-h4 primary--text">
          {{ `${user.last_name}, ${user.first_name}` }}
        </p>
        <a
          href="https://outlook.office.com"
          target="_blank"
          class="text-subtitle-2 primary--text"
        >
          <v-icon color="primary" small> mdi-email</v-icon>
          {{ user.mail }}
        </a>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import userAvatar from '@/components/user-avatar.vue'
export default {
  name: 'user-card-small',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  components: {
    userAvatar
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

a {
  text-decoration: none;
}
</style>
