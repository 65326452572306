<script>
import { mapState } from 'vuex'
export default {
  name: 'search-card',
  props: {
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    catalogUrl:
      'https://search.livebrary.com/search/?searchtype=X&SORT=D&searcharg=[SEARCH_QUERY]&searchscope=20'
  }),
  computed: {
    ...mapState({
      search: (state) => state.search
    }),
    searchInput: {
      get() {
        return this.search.input
      },
      set(value) {
        this.$store.dispatch('setSearch', { input: value })
      }
    },
    searchType: {
      get() {
        return this.search.type
      },
      set(value) {
        this.$store.dispatch('setSearch', { type: value })
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('onSubmit')
      if (this.searchType === 'catalog') this.searchCatalog()
      if (this.searchType === 'website') this.searchWebsite()
    },
    searchCatalog() {
      const url = this.catalogUrl.replace(
        '[SEARCH_QUERY]',
        this.searchInput.replaceAll(' ', '+')
      )
      window.open(url, '_blank')
    },
    searchWebsite() {
      console.log('searchWebsite set temp no results')
      this.$store.dispatch('setSearch', { results: [] })
    }
  }
}
</script>
<template>
  <v-card color="primary" dark flat tile>
    <v-card-text class="secondary--text">
      <v-text-field
        v-model="searchInput"
        append-outer-icon="mdi-magnify"
        color="secondary"
        clearable
        dark
        filled
        :hint="
          searchType === 'catalog'
            ? 'Search Books, Movies, Music, etc'
            : 'Search Events, Programs, Resources, etc..'
        "
        :label="
          searchType === 'catalog' ? 'Search  Catalog...' : 'Search  Website...'
        "
        outlined
        @click:append-outer="onSubmit"
        @keypress.enter="onSubmit"
      ></v-text-field>
      <v-sheet
        color="transparent"
        class="d-flex align-end justify-space-between"
      >
        <v-radio-group
          v-model="searchType"
          color="secondary"
          dark
          hide-details=""
          row
          class="mt-0"
        >
          <v-radio
            label="Catalog"
            value="catalog"
            color="secondary"
            dark
          ></v-radio>
          <v-radio
            color="secondary"
            dark
            label="Website"
            value="website"
          ></v-radio>
        </v-radio-group>

        <v-btn
          v-if="showClose"
          small
          color="secondary"
          text
          tile
          @click="$emit('close')"
          ><v-icon color="secondary" small left> mdi-chevron-up </v-icon>
          close</v-btn
        >
      </v-sheet>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped></style>
