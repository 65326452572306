var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "color": "primary",
      "extension-height": _vm.isAuth ? 75 : 0
    },
    scopedSlots: _vm._u([_vm.isAuth ? {
      key: "extension",
      fn: function fn() {
        return [_c('v-sheet', {
          attrs: {
            "color": "transparent",
            "width": "100%"
          }
        }, [_c('v-slide-y-transition', [false ? _c('search-card') : _vm._e()], 1), _vm.isCms ? _c('nav-cms') : _c('nav-main', {
          attrs: {
            "breakpoint": _vm.breakpoint,
            "navItems": _vm.app.nav
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-img', {
    staticClass: "hoverPointer",
    attrs: {
      "src": "/images/twitterlogo.jpg",
      "contain": "",
      "height": "auto",
      "max-width": "35"
    },
    on: {
      "click": _vm.toHome
    }
  }), _c('v-toolbar-title', {
    staticClass: "hoverPointer text-h5 secondary--text font-weight-bold ml-2",
    on: {
      "click": _vm.toHome
    }
  }, [_vm._v(_vm._s(_vm.app.title || ''))]), _c('v-spacer'), _vm.isAuth ? _c('v-sheet', {
    staticClass: "d-flex",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "secondary",
      "text": "",
      "tile": ""
    },
    on: {
      "click": _vm.toggleSearch
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("SEARCH ")], 1), _c('header-menu')], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }