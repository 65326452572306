import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import api from '@/utils/api.js'
// import weather from '../data/tempWeather'
export default new Vuex.Store({
  state: {
    app: {
      title: 'EIPL STAFF',
      nav: []
    },
    appLoading: true,
    isFirstLoad: true,
    modal: {
      component: '',
      data: {},
      options: {},
      value: false
    },
    roles: ['admin', 'content', 'user'],
    search: {
      input: '', //search field input
      results: null, // array of results returned or null for no search placed
      type: 'catalog', //search catalog or eipl.org
      value: false //show search bottom sheet
    },
    snackbar: {
      options: {
        // app: true,
        // centered: true,
        // outlined: true,
        // text: true,
        // timeout: 2500,
        // top: true
        border: 'left',
        defaultColor: 'primary',
        'multi-line': true,
        center: true,
        tile: true,
        timeout: 3000,
        top: true,
        statusText: null,
        vertical: true
      },
      color: '',
      message: '',
      value: false
    },
    settings: {},
    theme: {},
    user: {},
    weather: {},
    weatherForecastShow: false
  },
  getters: {
    isAdmin(state) {
      // return true
      return state?.user?.role?.indexOf('isAdmin') > -1
    },
    isAuth(state) {
      return state.user.id !== undefined
    },
    settingsByCat(state) {
      const settings = { app: state.app }
      //   const settings = {
      //     app: {},
      //     board: {},
      //     header: {},
      //     item: {},
      //     theme: {},
      //     ticker: {},
      //   }
      //   Object.keys(state.settings).forEach(setting => {
      //     const str = setting.substring(0, 3)
      //     //TODO: map these to object
      //     switch (str) {
      //       case 'app':
      //         settings.app[setting] = state.settings[setting]
      //         break
      //       case 'boa':
      //         settings.board[setting] = state.settings[setting]
      //         break
      //       case 'hea':
      //         settings.header[setting] = state.settings[setting]
      //         break
      //       case 'ite':
      //         settings.item[setting] = state.settings[setting]
      //         break
      //       case 'the':
      //         settings.theme[setting] = state.settings[setting]
      //         break
      //       case 'tic':
      //         settings.ticker[setting] = state.settings[setting]
      //         break
      //       default:
      //         console.log(str, 'is not found')
      //         break
      //     }
      //   })
      return settings
    }
  },
  actions: {
    clipboardWriteText({ dispatch }, text = '') {
      const snackbarData = {
        color: 'success',
        message: 'Copied to clipboard!',
        value: true
      }
      return new Promise(async (res, rej) => {
        if (!text) {
          snackbarData.color = 'error'
          snackbarData.message = 'No text received to copy'
        }
        try {
          await navigator.clipboard.writeText(text)
          res('CLIPBOARD WRITTED!')
        } catch (err) {
          snackbarData.color = 'error'
          snackbarData.message = 'CLIPBOARD WRITE ERROR!'
          console.log(err)
          rej('CLIPBOARD WRITE ERROR!')
        } finally {
          dispatch('snackbar', snackbarData)
        }
      })
    },
    closeModal({ commit }) {
      const defaultModal = {
        component: '',
        data: {},
        options: {},
        value: false
      }
      commit('COMMIT_MODAL', defaultModal)
    },
    closeSearch({ commit }) {
      const defaultSearch = {
        input: '',
        results: null,
        type: 'catalog',
        value: false
      }
      commit('COMMIT_SEARCH', defaultSearch)
    },
    async logout() {
      const resp = await api.get(
        process.env.VUE_APP_AUTH_URL + '/logout',
        {},
        false
      )
      if (resp) window.location.assign('/login')
    },
    setAppData({ commit }, data) {
      commit('COMMIT_APP', data)
    },
    setAppLoading({ commit }, data) {
      commit('COMMIT_APPLOADING', data)
    },
    setModal({ commit }, data) {
      commit('COMMIT_MODAL', data)
    },
    setSearch({ commit, state }, data) {
      commit('COMMIT_SEARCH', { ...state.search, ...data })
    },
    setUserdata({ commit }, data) {
      commit('COMMIT_USER', data)
    },
    setWeather({ commit }, data) {
      commit('COMMIT_WEATHER', data)
    },
    snackbar({ commit, state }, { color, message, value }) {
      // toggleSnackbar(color, message, value) {
      const sb = {
        ...state.snackbar,
        options: {
          ...state.snackbar.options
        },
        color,
        message,
        value
      }
      commit('COMMIT_SNACKBAR', sb)
    },
    testAuth({ commit, state }) {
      return new Promise(async (res, rej) => {
        let endpoint = process.env.VUE_APP_AUTH_URL
        let result = null
        if (state.isFirstLoad) {
          endpoint += '/init'
          // endpoint += '/user'
        }
        try {
          result = await api.get(endpoint, {}, false)
          console.log(result)
        } catch (err) {
          console.log(err)
          rej(err)
        }
        if (result.user) commit('COMMIT_USER', result.user)
        if (result.app) commit('COMMIT_APP', result.app)

        console.log(result.app)
        console.log(state)
        res(result)
      })
    },
    themeSet({ commit }, { $vuetify, theme }) {
      commit('COMMIT_THEME', theme)
      $vuetify.theme.dark = theme.isDark
      $vuetify.theme.themes.dark = {
        ...$vuetify.theme.themes.dark,
        ...theme.dark
      }
      $vuetify.theme.themes.light = {
        ...$vuetify.theme.themes.light,
        ...theme.light
      }
      localStorage.setItem(
        'settings_theme',
        JSON.stringify({
          isDark: $vuetify.theme.dark,
          dark: $vuetify.theme.themes.dark,
          light: $vuetify.theme.themes.light
        })
      )
    },
    toggleWeatherForecastShow({ state, commit }) {
      commit('COMMIT_WEATHER_FORECAST_SHOW', !state.weatherForecastShow)
    }
  },
  mutations: {
    COMMIT_APP(state, app) {
      console.log(app)
      state.app = { ...app }
    },
    COMMIT_APPLOADING(state, isLoading) {
      state.appLoading = isLoading
    },
    COMMIT_IS_FIRST_LOAD(state, isFirstLoad) {
      state.isFirstLoad = isFirstLoad
    },
    COMMIT_MODAL(state, modal) {
      state.modal = { ...state.modal, ...modal }
    },
    COMMIT_SEARCH(state, search) {
      state.search = { ...search }
    },
    COMMIT_SETTINGS(state, settings) {
      state.settings = { ...settings }
    },
    COMMIT_SNACKBAR(state, snackbar) {
      state.snackbar = { ...snackbar }
    },
    COMMIT_THEME(state, theme) {
      state.theme = { ...theme }
    },
    COMMIT_USER(state, data) {
      state.user = { ...data }
    },
    COMMIT_WEATHER(state, data) {
      state.weather = { ...data }
    },
    COMMIT_WEATHER_FORECAST_SHOW(state, data) {
      state.weatherForecastShow = data
    }
  }
})
