var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', _vm._b({
    key: _vm.state,
    attrs: {
      "color": _vm.snackbar.color,
      "transition": "fade-transition"
    },
    on: {
      "input": _vm.onClose
    },
    model: {
      value: _vm.state,
      callback: function callback($$v) {
        _vm.state = $$v;
      },
      expression: "state"
    }
  }, 'v-snackbar', _vm.snackbar.options, false), [_c('h5', {
    staticClass: "body-1 font-weight-bold text-uppercase"
  }, [_vm._v(" " + _vm._s(_vm.snackbar.color || _vm.snackbar.color) + ": ")]), _vm._v(" " + _vm._s(_vm.snackbar.message) + " "), _c('v-btn', {
    staticClass: "closebtn",
    attrs: {
      "icon": "",
      "color": "white"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.onClose.apply(null, arguments);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close-thick")])], 1), _c('v-progress-linear', {
    staticClass: "progress",
    attrs: {
      "bottom": "",
      "color": "white",
      "value": _vm.progress
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }