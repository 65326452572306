var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('app-header'), _c('v-main', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "mainView"
  }, [_c('v-dialog', {
    attrs: {
      "value": _vm.appLoading,
      "hide-overlay": "",
      "persistent": "",
      "width": "300"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-4 secondary--text"
  }, [_vm._v(" Loading... "), _c('v-progress-linear', {
    staticClass: "mb-0",
    attrs: {
      "indeterminate": "",
      "color": "secondary"
    }
  })], 1)], 1)], 1), !_vm.appLoading ? _c('router-view', {
    key: "rv".concat(_vm.$route.path)
  }) : _vm._e()], 1), _c('search-sheet'), _c('snackbar', {
    key: "sb".concat(_vm.snackbar.value)
  }), _c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.scrollTopBtn,
      expression: "scrollTopBtn"
    }],
    attrs: {
      "app": "",
      "fixed": "",
      "fab": "",
      "bottom": "",
      "color": "primary",
      "right": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo(_vm.scrollTopTarget, _vm.scrollTopOptions);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("mdi-chevron-double-up")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }