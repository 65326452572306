var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "color": "transparent"
    }
  }, [_c('user-avatar', {
    attrs: {
      "user": _vm.user
    }
  })], 1), _c('v-sheet', {
    staticClass: "d-flex flex-column align-start justify-start pl-4",
    attrs: {
      "color": "transparent"
    }
  }, [_c('p', {
    staticClass: "text-h4 primary--text"
  }, [_vm._v(" " + _vm._s("".concat(_vm.user.last_name, ", ").concat(_vm.user.first_name)) + " ")]), _c('a', {
    staticClass: "text-subtitle-2 primary--text",
    attrs: {
      "href": "https://outlook.office.com",
      "target": "_blank"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v(" mdi-email")]), _vm._v(" " + _vm._s(_vm.user.mail) + " ")], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }