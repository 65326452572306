var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "app": "",
      "fullscreen": "",
      "transition": "dialog-bottom-transition",
      "scrollable": "",
      "value": _vm.search.value
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "color": "primary",
      "max-height": "65"
    }
  }, [_c('v-toolbar-title', {
    staticClass: "secondary--text"
  }, [_vm._v("SEARCH")]), _c('v-spacer'), _c('v-toolbar-items', [_c('v-icon', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.closeSearch
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('search-card'), _c('v-card-text', {
    staticClass: "primary--text pa-4"
  }, [_vm.search.type === 'catalog' ? _c('p', [_vm._v(" Catalog search will open results from catalog in a new tab. ")]) : _vm._e(), _vm.search.type === 'website' ? _c('p', [_vm._v(" " + _vm._s(_vm.resultsMessage()) + " ")]) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }