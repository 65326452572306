<script>
import searchCard from '@/components/search-card.vue'
import { mapState } from 'vuex'
export default {
  name: 'search-sheet',
  components: {
    searchCard
  },
  computed: {
    ...mapState({
      search: (state) => state.search
    })
  },
  methods: {
    closeSearch() {
      this.$store.dispatch('closeSearch')
    },
    resultsMessage() {
      let message = null
      //results
      //null: no search placed
      //[] < 0 no results found
      // [] > 0 results found
      if (!Array.isArray(this.search.results))
        message = 'Search staff web for Forms, Programs, Resources, Tools...'

      if (Array.isArray(this.search.results) && this.search.results.length <= 0)
        message = `No results found for "${this.search.input}"`

      return message
    }
  },
  mounted() {
    const body = document.querySelector('body')
    body.style.setProperty('overflow', 'hidden')
  },
  beforeDestroy() {
    const body = document.querySelector('body')
    body.style.setProperty('overflow', 'auto')
  }
}
</script>
<template>
  <v-dialog
    app
    fullscreen
    transition="dialog-bottom-transition"
    scrollable
    :value="search.value"
  >
    <v-card flat tile>
      <v-toolbar color="primary" max-height="65">
        <v-toolbar-title class="secondary--text">SEARCH</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-icon @click="closeSearch" color="secondary">mdi-close</v-icon>
          <!-- <v-icon class="accent--text pl-2" @click.stop="$store.dispatch('toggleSideNav')">{{ this.sideNav ? 'mdi-menu' : 'mdi-menu' }}</v-icon> -->
        </v-toolbar-items>
      </v-toolbar>
      <search-card></search-card>
      <v-card-text class="primary--text pa-4">
        <p v-if="search.type === 'catalog'">
          Catalog search will open results from catalog in a new tab.
        </p>
        <p v-if="search.type === 'website'">
          {{ resultsMessage() }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
