var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    attrs: {
      "color": _vm.backgroundColor,
      "size": _vm.size
    }
  }, [_vm.contentType === 'text' ? _c('span', {
    class: "".concat(_vm.textColor, "--text font-weight-bold")
  }, [_vm._v(_vm._s(_vm.contentText))]) : _vm._e(), _vm.contentType === 'image' ? _c('span', {
    staticClass: "avatarImage",
    domProps: {
      "innerHTML": _vm._s(_vm.contentImage)
    }
  }, [_vm._v("!!")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }