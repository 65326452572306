<template>
  <v-avatar :color="backgroundColor" :size="size">
    <span
      v-if="contentType === 'text'"
      :class="`${textColor}--text font-weight-bold`"
      >{{ contentText }}</span
    >
    <span
      v-if="contentType === 'image'"
      v-html="contentImage"
      class="avatarImage"
      >!!</span
    >
  </v-avatar>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  name: 'user-avatar',
  props: {
    backgroundColor: {
      type: String,
      default: () => 'primary'
    },
    size: {
      type: [String, Number],
      default: 50
    },
    textColor: {
      type: String,
      default: 'secondary'
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    // ...mapState({
    //   user: (state) => state.user
    // }),
    contentImage() {
      if (this?.user?.azure?.photo) {
        return this.user.azure.photo
      }
      return ''
    },
    contentText() {
      return (
        this?.user.first_name[0].toUpperCase() +
          this?.user.last_name[0].toUpperCase() || ''
      )
    },
    contentType() {
      return this?.user?.avatar || this?.user?.azure?.photo ? 'image' : 'text'
    }
  }
}
</script>

<style lang="scss" scoped>
.avatarImage {
  height: 50px;
  width: 50px;
}
</style>
