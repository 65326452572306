<template>
  <v-menu
    v-model="userMenu"
    bottom
    :close-on-content-click="false"
    close-delay="250"
    offset-y
    :open-on-hover="false"
    @input="onInput"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-sheet color="transparent" v-bind="attrs" v-on="on">
        <user-avatar
          backgroundColor="secondary"
          size="35"
          textColor="primary"
          :user="user"
        ></user-avatar>
      </v-sheet>
    </template>
    <v-card flat tile width="400" class="mt-2">
      <v-card-text class="pa-0">
        <user-card :user="user"></user-card>
        <!-- <v-list dense>
          <v-list-item>
        <v-list-item-avatar>
          <v-icon color="primary">mdi-theme-light-dark</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="text-center primary--text">
          <ThemeToggle></ThemeToggle>
        </v-list-item-content>
      </v-list-item>
          <v-list-item :to="{ name: 'user' }">
            <v-list-item-action>
              <user-avatar size="40"></user-avatar>
            </v-list-item-action>
            <v-list-item-content class="text-left primary--text">
              <v-list-item-title class="text-subtitle-1 font-weight-bold"
                >PROFILE</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon color="primary" large>mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content class="text-left primary--text">
              <v-list-item-title
                @click="logout"
                class="text-subtitle-1 font-weight-bold"
                >LOGOUT</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn small color="primary" text tile @click="logout"> -->
        <v-btn small color="primary" text tile :to="{ name: 'logout' }">
          <v-icon color="primary" left small>mdi-logout-variant</v-icon>

          LOGOUT
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
// import ThemeToggle from '@/components/Controls/SwitchTheme'
import userAvatar from '@/components/user-avatar.vue'
import userCard from '@/components/user-card-small.vue'
import { mapGetters, mapState } from 'vuex'
//MAP NAVITEMS TO ROLES TO SHOW
export default {
  name: 'header-menu',
  // components: { DemoResetBtn, ThemeToggle },
  components: { userAvatar, userCard },
  data: () => ({
    userMenu: false
  }),
  computed: {
    ...mapGetters(['isAdmin', 'isAuth', 'settingsByCat']),
    ...mapState({
      user: (state) => state.user
    })
  },
  methods: {
    hasRole(role) {
      return this.user?.role?.includes(role)
    },
    logout() {
      this.$store.dispatch('logout')
    },
    //emit menu state so parent menu can track visibility
    onInput(isVisible) {
      this.$emit('onProfileMenu', isVisible)
    }
  }
}
</script>

<style scoped></style>
