<script>
export default {
  name: 'nav-main',
  props: {
    breakpoint: {
      //pass in vuetify breakpoints
      type: Object,
      required: true
    },
    navItems: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    navItemSelected: null,
    highlightSubNavItem: null,
    subNavExpansionPanel: null
  }),
  computed: {
    breadCrumbItems() {
      const routeOptions = {
        home: {
          icon: 'fas fa-home',
          text: 'Home'
        },
        kids: {
          text: 'Kids Corner'
        }
      }
      let breadCrumbs = []
      let path = ''
      let text = ''
      const cleanUrl = this.$route.fullPath.split('?')
      console.log(cleanUrl)
      cleanUrl[0].split('/').forEach((pathItem) => {
        console.log('pathItem', pathItem)
        path += pathItem === '' ? '/' : `${pathItem}`
        text = pathItem === '' ? 'Home' : pathItem
        console.log(path)
        console.log(text)
        console.log(routeOptions[text.toLowerCase()])
        breadCrumbs.push({
          text: routeOptions[text.toLowerCase()]
            ? routeOptions[text.toLowerCase()]['text']
            : text,
          href: path,
          icon: routeOptions[text.toLowerCase()]
            ? routeOptions[text.toLowerCase()].icon
            : null
        })
        console.log(breadCrumbs)
      })
      return breadCrumbs
    },
    isXSmall() {
      return this.breakpoint?.name === 'xs'
    }
  },
  methods: {
    navClicks(item) {
      console.log(item)
      if (item === 'search') {
        this.showSearch = !this.showSearch
      }
    }
  },
  mounted() {
    console.log('nav-main mounted')
  }
}
</script>
<template>
  <v-sheet color="transparent" class="nav-row">
    <v-card
      v-for="navItem in navItems"
      :key="'navFlex' + navItem.id || navItem.text"
      tile
      class="pa-1"
    >
      <v-menu
        open-on-click
        open-on-hover
        close-on-click
        :close-on-content-click="breakpoint.mdAndUp ? true : false"
        elevation="0"
        min-width="100%"
        bottom
        nudge-bottom
        offset-y
        transition="scroll-y-transition"
        v-on:input="
          $event === true
            ? (navItemSelected = navItem.id)
            : (navItemSelected = null)
        "
      >
        <template v-slot:activator="{ on }">
          <v-sheet
            v-on="on"
            class="primary--text d-flex align-center justify-center text-truncate"
            :class="`${isXSmall && 'flex-column '} ${
              navItemSelected === navItem.id
                ? 'primary secondary--text'
                : 'secondary primary--text'
            } `"
            height="45px"
            width="100%"
            @mouseover="navItemSelected = navItem.id"
          >
            <v-icon
              :left="!isXSmall"
              :color="navItemSelected === navItem.id ? 'secondary' : 'primary'"
              :small="isXSmall"
              >mdi-{{ navItem.icon }}</v-icon
            >
            <br v-if="isXSmall" />
            <font
              class="ml-1 font-weight-bold"
              :class="isXSmall ? 'caption' : ''"
              >{{ navItem.text }}</font
            >
          </v-sheet>
        </template>
        <v-card
          color="secondary"
          tile
          width="98%"
          :class="breakpoint.smAndDown ? 'mt-4' : 'mt-4'"
          class="subnav-menu-card mx-auto mb-4"
        >
          <v-card-text class="primary--text subNavPanelCardText">
            <v-scale-transition leave-absolute>
              <v-row justify="start" align="start">
                <template v-if="breakpoint.smAndDown">
                  <v-expansion-panels
                    v-model="subNavExpansionPanel"
                    accordion
                    tile
                    class="primary--text"
                  >
                    <v-expansion-panel
                      v-for="subLinks in navItem.children"
                      :key="subLinks.id || subLinks.text"
                      class="primary--text"
                    >
                      <v-expansion-panel-header>
                        <v-sheet
                          color="transparent"
                          width="100%"
                          height="100%"
                          class="d-flex align-center justify-start primary--text"
                        >
                          <v-icon color="primary" class="mr-2"
                            >mdi-{{ subLinks.icon }}</v-icon
                          >
                          {{ subLinks.text }}
                        </v-sheet>
                        <v-spacer></v-spacer>
                        <template v-slot:actions>
                          <v-icon color="primary">$vuetify.icons.expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="secondary-nav-panel-content primary--text secondary text-xs-left px-4 py-2"
                      >
                        <ul>
                          <li
                            v-for="link in subLinks.children"
                            transition="fade-transition"
                            :key="
                              (link.id || link.text) +
                              (subLinks.id || subLinks.text)
                            "
                            left
                            @mouseenter="highlightSubNavItem = link.text"
                            @mouseleave="highlightSubNavItem = null"
                            class="font-weight-medium py-1"
                            :style="
                              highlightSubNavItem === link.text
                                ? 'background-color: rgba(0, 0, 102, 0.35);'
                                : ''
                            "
                          >
                            {{ link.text }}
                          </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <template v-if="breakpoint.mdAndUp">
                  <template v-for="subLinks in navItem.children">
                    <v-col
                      col="12"
                      sm="4"
                      :key="subLinks.id || subLinks.text"
                      class="text-xs-left pa-3"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex align-center pb-2 primary--text"
                      >
                        <v-icon color="primary" class="mr-2"
                          >mdi-{{ subLinks.icon }}</v-icon
                        >
                        <font
                          class="title"
                          :key="(subLinks.id || subLinks.text) + 'title'"
                          >{{ subLinks.text }}</font
                        >
                      </v-sheet>
                      <v-divider
                        class="primary--text"
                        light
                        :key="(subLinks.id || subLinks.text) + 'divider'"
                      ></v-divider>
                      <template>
                        <div class="text-xs-left mt-1">
                          <ul>
                            <li
                              v-for="link in subLinks.children"
                              transition="fade-transition"
                              :key="
                                (link.id || link.text) +
                                (subLinks.id || subLinks.text)
                              "
                              left
                              @mouseenter="highlightSubNavItem = link.text"
                              class="font-weight-medium"
                              :style="
                                highlightSubNavItem === link.text
                                  ? 'background-color: rgba(0, 0, 102, 0.35);'
                                  : ''
                              "
                            >
                              {{ link.text }}
                            </li>
                          </ul>
                        </div>
                      </template>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-scale-transition>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card>
  </v-sheet>
  <!-- <v-row v-if="$route.name !== 'index'" align-center>
            <v-col col="12">
              <v-breadcrumbs
                class="breadCrumbs"
                :items="breadCrumbItems"
                active-class="breadCrumbActive"
                nuxt
                exact
                ripple
              >
                <template v-slot:divider>
                  <v-icon color="secondary" dark class="px-2">fas fa-caret-right</v-icon>
                </template>
                <template v-slot:item="props">
                  <v-icon
                    v-if="props.item.icon"
                    small
                    color="secondary"
                    class="pr-1 mb-1"
                  >{{props.item.icon}}</v-icon>
                  <a
                    class="breadCrumbs"
                    :href="props.item.href"
                    :style="props.item.href === $route.fullPath ? {'text-decoration': 'underline'} : ''"
                  >{{ props.item.text.toUpperCase() }}</a>
                </template>
              </v-breadcrumbs>
            </v-col>
          </v-row>-->
</template>

<style scoped>
.nav-row {
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.nav-row > * {
  flex: 1 1 33.333%;
}

.v-menu__content {
  box-shadow: none;
}

.secondary-nav-panel-content {
  max-height: 20rem;
  overflow-y: auto;
}

/* TESTING BACKGROUND LOGO */
/* .subNavPanelCardText::before {
  content: ' ';
  background-image: url('@/static/eiplogo_white.svg');
  background-size: cover;
  position: absolute;
  top: 150px;
  right: 0px;
  bottom: 0px;
  left: 150px;
  opacity: 0.05;
  transform: translate(-50%);
} */
</style>
